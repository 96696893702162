/* RESET
----------------------------------------------------------------------------------------------------*/
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, caption, canvas, center, cite, code,
dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, form, footer, header, hgroup, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav,object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, tt, table, tbody, textarea, tfoot, thead, time, tr, th, td, u, ul, var, video  { 
	font-family: inherit; 
	font-size: 100%; 
	font-weight: inherit; 
	font-style: inherit; 
	vertical-align: baseline; 
	white-space: normal;
	text-align: left; 
	margin: 0; 
	padding: 0; 
	border: 0; 
	outline: 0;
	background: transparent; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { 
	display: block; }
								  
ol, ul { list-style: none; }

img { line-height: 0px; }

blockquote, q { quotes: none; }

:focus { outline: 0; }

table { 
	border-collapse: collapse; 
	border-spacing: 0; }

a { text-decoration: none; }

button > * {
	pointer-events: none;
}

/*** fonts ***/
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,700&display=swap');

@font-face {
  font-family: 'icomoon';
  src:  url('../webfonts/icomoon.eot?5drxtz');
  src:  url('../webfonts/icomoon.eot?5drxtz#iefix') format('embedded-opentype'),
    url('../webfonts/icomoon.ttf?5drxtz') format('truetype'),
    url('../webfonts/icomoon.woff?5drxtz') format('woff'),
    url('../webfonts/icomoon.svg?5drxtz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e942";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-linkedin2:before {
  content: "\eaca";
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #002F6C url(../images/finland.svg) right no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
	color: #002F6C;
}

h1, h2 {
	font-size: 350%;
	letter-spacing: -.07em;
  color: #002F6C;
  font-family: 'Roboto';
  font-weight: 300;
}

h3 {
	font-weight: 700;
	color: #002F6C;
}

.lead {
  font-size: 170%;
  font-weight: 100;
  margin-bottom: 2em;
  letter-spacing: -.03em;
  color:#002F6C;
  width: 90%
}

.lead::after {
  content: "";
  width: 60px;
  display: block;
  height: 2px;
  background: #002F6C;
  top: .65em;
  position: relative;
}

.tlist li {
  margin-top: 1em;
  padding-left: 1em;
  text-indent: -.5em;
}

.tlist li::before {
  content: '';
  display: inline-block;
  background: #002F6C;
  width: .5em;
  height: .5em;
  position: relative;
  left: -.5em;
  border-radius: 3px
}

.mapboxgl-map {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.datasetby {
  text-align: center;
  font-size: 80%;
  grid-column-start: 1;
  grid-column-end: 4;
}