
.btn {
    border: 0;
    font-size: 120%;
    text-transform: uppercase;
    font-family: "Ubuntu", sans-serif;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    padding: 0px 20px;
    height: 66px;
    cursor: pointer;
    transition: opacity .3s;
    line-height: 66px;
    min-width: 66px;
    position: relative;
    /*max-width: 320px;*/
}

.clicksrf {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.btn:hover {
    opacity: .8;
    transition: opacity .3s;
}

.btnIcon {
    margin-right: .5em;
    font-size: 32px;
    position: relative;
    vertical-align: middle;
}

.btn.hasIcon {
    text-indent: -32px;
}

.btn.hasIcon.noLabel {
    text-indent: 0px;
}

.btn.hasIcon.noLabel .btnIcon {
    margin-right: 0px;
}

.btLabel {
    white-space: nowrap;
}

.rounded {
    border-radius: 66px;
}

.btn:focus {
    box-shadow: 0px 0px 3px 1px white;
}