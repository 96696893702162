.App {
  text-align: center;
  max-width: 1600px;
  position: relative;
  margin: 0px auto;
  padding: 15px 0px;
  top: 100vh;
}

.App.loading {
  top: 100vh;
}

.App.loaded {
  top: 0vh;
  transition: 1s top cubic-bezier(0.075, 0.82, 0.165, 1);
}

.App .mapholder > div:nth-of-type(1) {
  width: 100% !important;
  height: 50vh !important;
  min-height: 600px !important;
  position: relative;
}

.contacts {
  padding: 2em 3em;
  box-sizing: border-box;
  background: rgb(12, 74, 156);
}

.contacts h3 {
  color: white;
}

.contact {
  display: grid;
  grid-template-columns: 1fr;
  word-break: break-word;
  column-gap: 15px;
  row-gap: 15px;
  box-sizing: border-box;
  color: white;
}

.contact .imgwrap {
  display: inline-block;
  border-radius: 100%;
  overflow: hidden;
  max-width: 180px;
  max-height: 180px;
}

.contact img {
  max-width: 180px;
  min-height: 180px;
  line-height: 0;
}

.contact figure {
  text-align: center;
  padding: 1em;
}

.contact ul {
  align-self: end;
}

.contact ul li {
  margin-bottom: .5em;
  text-align: center;
}

.contact ul li.cname {
  color: white;
  font-size: 175%;
  font-weight: 100;
  letter-spacing: -.05em;
}

.contact ul li.phone {
  font-size: 150%;
  font-weight: 100;
  padding-top: .5em;
}

.contact ul li.social a {
  color: white;
  font-size: 150%;
  margin-right: 1em;
  display: inline-block;
  margin-top: 15px;
}

.contact ul li.social a:last-child {
  margin-right: 0;
}

.macrodata {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 15px;
  padding: 3em 15px;
  box-sizing: border-box;
  background:rgb(241, 247, 255)
}

.apexcharts-canvas {
  max-width: 100% !important;
}

.macrodata h3, .contacts h3 {
  text-align: center;
  text-transform: uppercase;
}

.macrodata > div {
  align-self: end;
}

.App header {
  /*background-color: white;*/
  border-bottom: 1px solid rgba(0, 47, 108, 0.2);
  padding: 2em 6% 2em 2%;
  display: grid;
  grid-template-columns: 95px auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.App header h1 {
  color: white;
}

.App .motto {
  font-size: 150%;
  letter-spacing: -0.03em;
  /*color: #909090;*/
  color: white;
  margin-left: 0.1em;
  font-weight: 100;
}

.App header figure {
  grid-row-start: 1;
  grid-row-end: 3;
  align-self: center;
  text-align: center;
}

.mainlogo {
  max-width: 80px;
}

#references {
  padding: 3em 0px;
}

#references h1 {
  letter-spacing: -.04em;
  font-size: 300%;
  color: white;
  text-align: center;
  margin-bottom: 1em;
  font-weight: 100;
}

.references {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 15px;
  column-gap: 15px;
}

.references a {
  display: block;
  text-align: center;
  align-self: center;
}

.references a img {
  max-width: 162px;
}

#content1, #content2 {
  padding: 3em;
  background: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3em;
}

#content2 {
  grid-template-columns: 1fr;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: rgb(241, 247, 255);
  border-top: 1px solid rgba(0, 47, 108, 0.05);
}

#content2 ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 2em;
  row-gap: 2em;
}

#content2 ul li {
  text-align: center;
}

#content2 ul li figure {
  text-align: center;
  margin-bottom: 1em;
}

#content2 ul li img {
  max-width: 128px;
}

#content1 h2, #content2 h2 {
  letter-spacing: -.04em;
  font-size: 250%;
  margin-bottom: .3em;
  font-weight: 100;
}

#content2 h2 {
  text-align: center;
  margin-bottom: 1em;
}

.mapholder {
  position: relative;
}

.mapholder .scrollenabler {
  background: rgba(0, 47, 108, 0.05);
  position: absolute;
  width: 40px;
  right: 0px;
  top: 0px;
  height: 50vh !important;
  min-height: 600px !important;
}

footer a {
  color:  rgba(91, 184, 255, 0.85);
}

footer div {
  margin-top: 2em;
}

footer div:before {
  display: block;
  content: " ";
  display: block;
  width: 60px;
  height: 2px;
  background: white;
  position: relative;
  left: 50%;
  transform: translateX(-30px);
}

footer p {
  text-align: center;
  margin: 2em 0px 2em 0px;
  color: white;
  font-size: 90%;
}

footer p:before {
  content: "©";
}

/***** more contact than one? ******/

.macrodata.h2 {
  grid-template-columns: repeat(2, 1fr);
}

.macrodata.h2 .chart:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
}

.macrodata.h2 .datasetby {
  grid-column-start: 1;
  grid-column-end: 3;
}



@media only screen and (max-width: 1600px) {

  .App {
    padding: 15px;
  }

}

@media only screen and (max-width: 1440px) {

  .macrodata, .macrodata.h2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .macrodata.h2 .chart:first-child {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .datasetby, .macrodata.h2 .datasetby {
    grid-column-end: 3;
  }

  .contacts {
    padding-left: 1em;
    padding-right: 1em;
  }

}

@media only screen and (max-width: 1280px) {

  .macrodata, .macrodata.h2 {
    grid-template-columns: repeat(1, 1fr);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .macrodata.h2 .chart:first-child {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .datasetby, .macrodata.h2 .datasetby {
    grid-column-end: 2;
  }

}

@media only screen and (max-width: 1024px) {

  .App header {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .poppanel.opened {
    grid-template-columns: 1fr;
  }
  
  .contacts {
    padding: 2em;
  }

  .contacts h3 {
    padding-bottom: 15px;
  }

	.contact {
    grid-template-columns: 210px auto
  }
  
  .contact ul li {
    text-align: left;
  }

  /*.contact .imgwrap {
    padding: 0px 15px;
  }*/

  #content2 ul, .references {
    grid-template-columns: repeat(4, 1fr)
  }

  .references a img {
    max-width: 144px;
    max-height: 96px;
  }

}

@media only screen and (max-width: 768px) {
  
  #content1 {
    grid-template-columns: 1fr;
    row-gap: 3em;
  }

  #content2 ul, .references {
    grid-template-columns: repeat(3, 1fr)
  }

}

@media only screen and (max-width: 600px) {
 
	.contact {
    grid-template-columns: 1fr
  }

  .contact ul li {
    text-align: center;
  }

  #content1 {
    padding: 1em;
  }
  
  #content1 h2 {
    font-size: 190%;
  }

  .lead {
    font-size: 135%;
  }

  #content2 ul {
    grid-template-columns: repeat(2, 1fr)
  }

}

@media only screen and (max-width: 540px) {
 
	.App header {
    grid-template-columns: 52px auto;
    padding: 15px;
  }

  .App header h1 {
    font-size: 250%;
  }
  
  .App header .motto {
    font-size: 120%;
  }
  
  .mainlogo {
    max-width: 52px;
  }
  
  .App header h1, .App header .motto {
    /*text-align: center;*/
  }

  .references {
    grid-template-columns: repeat(2, 1fr)
  }

  .references a img {
    max-width: 128px;
  }

}

@media only screen and (max-width: 400px) {

  #content2 {
    padding: 1em;
  }

  #content2 ul {
    column-gap: 1em;
  }

  #content2 ul li figure img {
    max-width: 96px;
  }

}