@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,700&display=swap);
/* RESET
----------------------------------------------------------------------------------------------------*/
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, caption, canvas, center, cite, code,
dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, form, footer, header, hgroup, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav,object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, tt, table, tbody, textarea, tfoot, thead, time, tr, th, td, u, ul, var, video  { 
	font-family: inherit; 
	font-size: 100%; 
	font-weight: inherit; 
	font-style: inherit; 
	vertical-align: baseline; 
	white-space: normal;
	text-align: left; 
	margin: 0; 
	padding: 0; 
	border: 0; 
	outline: 0;
	background: transparent; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { 
	display: block; }
								  
ol, ul { list-style: none; }

img { line-height: 0px; }

blockquote, q { quotes: none; }

:focus { outline: 0; }

table { 
	border-collapse: collapse; 
	border-spacing: 0; }

a { text-decoration: none; }

button > * {
	pointer-events: none;
}

/*** fonts ***/

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.e7a449ce.eot);
  src:  url(/static/media/icomoon.e7a449ce.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.ff8a429c.ttf) format('truetype'),
    url(/static/media/icomoon.65d6bf7e.woff) format('woff'),
    url(/static/media/icomoon.6497c42d.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e942";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-linkedin2:before {
  content: "\eaca";
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #002F6C url(/static/media/finland.ec69dd69.svg) right no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
	color: #002F6C;
}

h1, h2 {
	font-size: 350%;
	letter-spacing: -.07em;
  color: #002F6C;
  font-family: 'Roboto';
  font-weight: 300;
}

h3 {
	font-weight: 700;
	color: #002F6C;
}

.lead {
  font-size: 170%;
  font-weight: 100;
  margin-bottom: 2em;
  letter-spacing: -.03em;
  color:#002F6C;
  width: 90%
}

.lead::after {
  content: "";
  width: 60px;
  display: block;
  height: 2px;
  background: #002F6C;
  top: .65em;
  position: relative;
}

.tlist li {
  margin-top: 1em;
  padding-left: 1em;
  text-indent: -.5em;
}

.tlist li::before {
  content: '';
  display: inline-block;
  background: #002F6C;
  width: .5em;
  height: .5em;
  position: relative;
  left: -.5em;
  border-radius: 3px
}

.mapboxgl-map {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.datasetby {
  text-align: center;
  font-size: 80%;
  grid-column-start: 1;
  grid-column-end: 4;
}
.poppanel {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: rgb(12, 74, 156);
  box-shadow: 0px 10px 40px rgba(0, 47, 108, 0.1);
  border-top: 1px solid rgba(0, 47, 108, 0.1);
  z-index: 2;
  border-bottom: 1px solid rgb(230, 236, 243);
}

.poppanel.closed {
  max-height: 2em;
  -webkit-transition: 1s all ease-out;
  transition: 1s all ease-out;
  box-shadow: none;
  display: block;
}

.poppanel p.small {
  text-align: center;
  line-height: 2em;
  color: white;
}

.poppanel.opened p.small { 
  display: none;
}

.poppanel.opened {
  max-height: 100%;
  -webkit-transition: 1s max-height ease-out;
  transition: 1s max-height ease-out;
  display: grid;
  grid-template-columns: 25% auto;
  border-top-color: rgb(12, 74, 156);
  border-top-width: 2px;
}

.poppanel.opened:before {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  background:rgb(12, 74, 156);
  border-radius: 100%;
  position: absolute;
  -webkit-transform: translate(50%, -10px);
          transform: translate(50%, -10px);
  z-index: 1;
  left: 50%;
}

/*
.poppanel.U {
  bottom: 100%;
  transform: translateY(100%);
  transition: .3s all ease-out;
}

.poppanel.D {
  bottom: 0px;
  transition: .3s all ease-out;
}
*/

.btn {
    border: 0;
    font-size: 120%;
    text-transform: uppercase;
    font-family: "Ubuntu", sans-serif;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    padding: 0px 20px;
    height: 66px;
    cursor: pointer;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    line-height: 66px;
    min-width: 66px;
    position: relative;
    /*max-width: 320px;*/
}

.clicksrf {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.btn:hover {
    opacity: .8;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
}

.btnIcon {
    margin-right: .5em;
    font-size: 32px;
    position: relative;
    vertical-align: middle;
}

.btn.hasIcon {
    text-indent: -32px;
}

.btn.hasIcon.noLabel {
    text-indent: 0px;
}

.btn.hasIcon.noLabel .btnIcon {
    margin-right: 0px;
}

.btLabel {
    white-space: nowrap;
}

.rounded {
    border-radius: 66px;
}

.btn:focus {
    box-shadow: 0px 0px 3px 1px white;
}
.popup {
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	background-image :url(/static/media/open_1-min.23501498.jpg);
	background-position: center;
	background-size: cover;
    z-index: 1000;
	top: 0;
	left: 0;
}

.popup .darken {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHdJREFUeNrs0qENACAMRcGWMAQzMyljYCrwOLia/2yTy4iY8dH12lG7Xu92PJ8/dCKNNNJII4000kgjjTTSSCONNNJII4000kgjjTTSSCONNNJII4000kgjjTTSSCONNNJII4000kgjjTTSSCONNNJII33XW4ABAKCCitS0ZlV+AAAAAElFTkSuQmCC);
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 1001;
}

.popupMessage {
	margin-bottom: 1em;
	z-index: 1002;
}

.popup.loading,
.popup.saving,
.popup.answer {
	color: white;
}

.popup.alert {
	color: white;
}

.popup.error {
	background: rgba(255, 0, 0, .9);
	color: white;
}

.popup.success {
    color: #7dc124
}

.lds-dual-ring {
	display: block;
	position: relative;
	width: 64px;
	height: 64px;
	z-index: 1002;
}
.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 46px;
	height: 46px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid #fff;
	border-color: #fff transparent #fff transparent;
	-webkit-animation: lds-dual-ring 1.2s linear infinite;
	        animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
@keyframes lds-dual-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.popup.answer .btnContainer {
	min-width: 150px;
	margin-bottom: 5px;
}

.App .popup.loading header {
	background: none;
	z-index: 1002;
	top: 0px;
	position: fixed;
}

.App .popup.loading header h1, .App .popup.loading header .motto {
	color: white;
}
.App {
  text-align: center;
  max-width: 1600px;
  position: relative;
  margin: 0px auto;
  padding: 15px 0px;
  top: 100vh;
}

.App.loading {
  top: 100vh;
}

.App.loaded {
  top: 0vh;
  -webkit-transition: 1s top cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 1s top cubic-bezier(0.075, 0.82, 0.165, 1);
}

.App .mapholder > div:nth-of-type(1) {
  width: 100% !important;
  height: 50vh !important;
  min-height: 600px !important;
  position: relative;
}

.contacts {
  padding: 2em 3em;
  box-sizing: border-box;
  background: rgb(12, 74, 156);
}

.contacts h3 {
  color: white;
}

.contact {
  display: grid;
  grid-template-columns: 1fr;
  word-break: break-word;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap: 15px;
  row-gap: 15px;
  box-sizing: border-box;
  color: white;
}

.contact .imgwrap {
  display: inline-block;
  border-radius: 100%;
  overflow: hidden;
  max-width: 180px;
  max-height: 180px;
}

.contact img {
  max-width: 180px;
  min-height: 180px;
  line-height: 0;
}

.contact figure {
  text-align: center;
  padding: 1em;
}

.contact ul {
  align-self: end;
}

.contact ul li {
  margin-bottom: .5em;
  text-align: center;
}

.contact ul li.cname {
  color: white;
  font-size: 175%;
  font-weight: 100;
  letter-spacing: -.05em;
}

.contact ul li.phone {
  font-size: 150%;
  font-weight: 100;
  padding-top: .5em;
}

.contact ul li.social a {
  color: white;
  font-size: 150%;
  margin-right: 1em;
  display: inline-block;
  margin-top: 15px;
}

.contact ul li.social a:last-child {
  margin-right: 0;
}

.macrodata {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap: 15px;
  row-gap: 15px;
  padding: 3em 15px;
  box-sizing: border-box;
  background:rgb(241, 247, 255)
}

.apexcharts-canvas {
  max-width: 100% !important;
}

.macrodata h3, .contacts h3 {
  text-align: center;
  text-transform: uppercase;
}

.macrodata > div {
  align-self: end;
}

.App header {
  /*background-color: white;*/
  border-bottom: 1px solid rgba(0, 47, 108, 0.2);
  padding: 2em 6% 2em 2%;
  display: grid;
  grid-template-columns: 95px auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.App header h1 {
  color: white;
}

.App .motto {
  font-size: 150%;
  letter-spacing: -0.03em;
  /*color: #909090;*/
  color: white;
  margin-left: 0.1em;
  font-weight: 100;
}

.App header figure {
  grid-row-start: 1;
  grid-row-end: 3;
  align-self: center;
  text-align: center;
}

.mainlogo {
  max-width: 80px;
}

#references {
  padding: 3em 0px;
}

#references h1 {
  letter-spacing: -.04em;
  font-size: 300%;
  color: white;
  text-align: center;
  margin-bottom: 1em;
  font-weight: 100;
}

.references {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 15px;
  row-gap: 15px;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
}

.references a {
  display: block;
  text-align: center;
  align-self: center;
}

.references a img {
  max-width: 162px;
}

#content1, #content2 {
  padding: 3em;
  background: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3em;
  -webkit-column-gap: 3em;
     -moz-column-gap: 3em;
          column-gap: 3em;
}

#content2 {
  grid-template-columns: 1fr;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: rgb(241, 247, 255);
  border-top: 1px solid rgba(0, 47, 108, 0.05);
}

#content2 ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 2em;
  -webkit-column-gap: 2em;
     -moz-column-gap: 2em;
          column-gap: 2em;
  grid-row-gap: 2em;
  row-gap: 2em;
}

#content2 ul li {
  text-align: center;
}

#content2 ul li figure {
  text-align: center;
  margin-bottom: 1em;
}

#content2 ul li img {
  max-width: 128px;
}

#content1 h2, #content2 h2 {
  letter-spacing: -.04em;
  font-size: 250%;
  margin-bottom: .3em;
  font-weight: 100;
}

#content2 h2 {
  text-align: center;
  margin-bottom: 1em;
}

.mapholder {
  position: relative;
}

.mapholder .scrollenabler {
  background: rgba(0, 47, 108, 0.05);
  position: absolute;
  width: 40px;
  right: 0px;
  top: 0px;
  height: 50vh !important;
  min-height: 600px !important;
}

footer a {
  color:  rgba(91, 184, 255, 0.85);
}

footer div {
  margin-top: 2em;
}

footer div:before {
  display: block;
  content: " ";
  display: block;
  width: 60px;
  height: 2px;
  background: white;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}

footer p {
  text-align: center;
  margin: 2em 0px 2em 0px;
  color: white;
  font-size: 90%;
}

footer p:before {
  content: "©";
}

/***** more contact than one? ******/

.macrodata.h2 {
  grid-template-columns: repeat(2, 1fr);
}

.macrodata.h2 .chart:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
}

.macrodata.h2 .datasetby {
  grid-column-start: 1;
  grid-column-end: 3;
}



@media only screen and (max-width: 1600px) {

  .App {
    padding: 15px;
  }

}

@media only screen and (max-width: 1440px) {

  .macrodata, .macrodata.h2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .macrodata.h2 .chart:first-child {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .datasetby, .macrodata.h2 .datasetby {
    grid-column-end: 3;
  }

  .contacts {
    padding-left: 1em;
    padding-right: 1em;
  }

}

@media only screen and (max-width: 1280px) {

  .macrodata, .macrodata.h2 {
    grid-template-columns: repeat(1, 1fr);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .macrodata.h2 .chart:first-child {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .datasetby, .macrodata.h2 .datasetby {
    grid-column-end: 2;
  }

}

@media only screen and (max-width: 1024px) {

  .App header {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .poppanel.opened {
    grid-template-columns: 1fr;
  }
  
  .contacts {
    padding: 2em;
  }

  .contacts h3 {
    padding-bottom: 15px;
  }

	.contact {
    grid-template-columns: 210px auto
  }
  
  .contact ul li {
    text-align: left;
  }

  /*.contact .imgwrap {
    padding: 0px 15px;
  }*/

  #content2 ul, .references {
    grid-template-columns: repeat(4, 1fr)
  }

  .references a img {
    max-width: 144px;
    max-height: 96px;
  }

}

@media only screen and (max-width: 768px) {
  
  #content1 {
    grid-template-columns: 1fr;
    grid-row-gap: 3em;
    row-gap: 3em;
  }

  #content2 ul, .references {
    grid-template-columns: repeat(3, 1fr)
  }

}

@media only screen and (max-width: 600px) {
 
	.contact {
    grid-template-columns: 1fr
  }

  .contact ul li {
    text-align: center;
  }

  #content1 {
    padding: 1em;
  }
  
  #content1 h2 {
    font-size: 190%;
  }

  .lead {
    font-size: 135%;
  }

  #content2 ul {
    grid-template-columns: repeat(2, 1fr)
  }

}

@media only screen and (max-width: 540px) {
 
	.App header {
    grid-template-columns: 52px auto;
    padding: 15px;
  }

  .App header h1 {
    font-size: 250%;
  }
  
  .App header .motto {
    font-size: 120%;
  }
  
  .mainlogo {
    max-width: 52px;
  }
  
  .App header h1, .App header .motto {
    /*text-align: center;*/
  }

  .references {
    grid-template-columns: repeat(2, 1fr)
  }

  .references a img {
    max-width: 128px;
  }

}

@media only screen and (max-width: 400px) {

  #content2 {
    padding: 1em;
  }

  #content2 ul {
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
       -moz-column-gap: 1em;
            column-gap: 1em;
  }

  #content2 ul li figure img {
    max-width: 96px;
  }

}
