.popup {
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	background-image :url(../images/open_1-min.jpg);
	background-position: center;
	background-size: cover;
    z-index: 1000;
	top: 0;
	left: 0;
}

.popup .darken {
	background: url(../images/darken.png);
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 1001;
}

.popupMessage {
	margin-bottom: 1em;
	z-index: 1002;
}

.popup.loading,
.popup.saving,
.popup.answer {
	color: white;
}

.popup.alert {
	color: white;
}

.popup.error {
	background: rgba(255, 0, 0, .9);
	color: white;
}

.popup.success {
    color: #7dc124
}

.lds-dual-ring {
	display: block;
	position: relative;
	width: 64px;
	height: 64px;
	z-index: 1002;
}
.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 46px;
	height: 46px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.popup.answer .btnContainer {
	min-width: 150px;
	margin-bottom: 5px;
}

.App .popup.loading header {
	background: none;
	z-index: 1002;
	top: 0px;
	position: fixed;
}

.App .popup.loading header h1, .App .popup.loading header .motto {
	color: white;
}