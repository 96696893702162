.poppanel {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: rgb(12, 74, 156);
  box-shadow: 0px 10px 40px rgba(0, 47, 108, 0.1);
  border-top: 1px solid rgba(0, 47, 108, 0.1);
  z-index: 2;
  border-bottom: 1px solid rgb(230, 236, 243);
}

.poppanel.closed {
  max-height: 2em;
  transition: 1s all ease-out;
  box-shadow: none;
  display: block;
}

.poppanel p.small {
  text-align: center;
  line-height: 2em;
  color: white;
}

.poppanel.opened p.small { 
  display: none;
}

.poppanel.opened {
  max-height: 100%;
  transition: 1s max-height ease-out;
  display: grid;
  grid-template-columns: 25% auto;
  border-top-color: rgb(12, 74, 156);
  border-top-width: 2px;
}

.poppanel.opened:before {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  background:rgb(12, 74, 156);
  border-radius: 100%;
  position: absolute;
  transform: translate(50%, -10px);
  z-index: 1;
  left: 50%;
}

/*
.poppanel.U {
  bottom: 100%;
  transform: translateY(100%);
  transition: .3s all ease-out;
}

.poppanel.D {
  bottom: 0px;
  transition: .3s all ease-out;
}
*/